.terms-container {
    padding: 0px;
    max-width: 800px;
    margin: 0 auto;
    color: #757575;
    background-color: #d3d3d3;
    font-size: 0.7em;
  }
  
  h1, h2 {
    color: #757575;
  }
  
  p {
    font-size: 1.2em;
    line-height: 1.2;
    margin-bottom: 10px;
    color: #757575;
  }
  