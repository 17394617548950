.pricing-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  font-size: 1.2em;
  color: #333333; /* Darker text for better readability */
  background-color: #d3d3d3; /* Beige background to match Home page */
  min-height: 80vh;
  overflow-x: auto;
  overflow-y: hidden;
}

h1 {
  align-self: flex-start;
  margin-bottom: 20px;
  margin-left: 10%;
  color: #333333; /* Darker text color */
  font-size: 1.5em; /* Increase font size for better readability */
}

.pricing-table {
  width: 80%;
  max-width: 800px;
  border-collapse: collapse;
  background-color: #fff; /* Lighter background for contrast */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  border-radius: 15px;
  overflow: hidden;
  margin-top: 20px;
}

.pricing-table th, .pricing-table td {
  padding: 20px;
  text-align: center;
  border-bottom: 1px solid #9e65e0; /* Purple accent color */
  color: #333333; /* Dark text for better readability */
}

.pricing-table th {
  background-color: #e0e0e0; /* Light grey for headers */
  font-size: 1.0em;
}

.pricing-table td {
  font-size: 0.9em;
}

.pricing-table tr:last-child td {
  border-bottom: none;
}