.header {
    width: 100%;
    background-color: #1e1e1e;
    padding: 15px 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
  }
  
  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .use-it-now-button-container {
    margin-left: auto; /* Push the button to the right */
    margin-right: 30px;
  }
  
  .use-it-now-button {
    background-color: #9e65e0; /* Button background color */
    color: white;              /* Button text color */
    border: none;              /* Remove default border */
    padding: 10px 20px;        /* Button padding */
    font-size: 16px;           /* Font size */
    cursor: pointer;           /* Show pointer on hover */
    border-radius: 5px;        /* Rounded corners */
    transition: background-color 0.3s ease; /* Smooth hover effect */
  }
  
  .use-it-now-button:hover {
    background-color: #9f67e7; /* Slightly darker background on hover */
  }
  
  
  .header-title {
    font-size: 24px;
    color: #bb86fc;
    margin-left: 10px; /* Space between the icon and text */
  }
  