body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #d3d3d3; /* Beige color or you could use #d3d3d3 for a greyish tone */
  color: #333333; /* Darker font color for better readability on the lighter background */
}

.App-header {
  background-color: #d3d3d3; /* Match the body background */
  padding: 30px 10px;
  text-align: left;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.5);
  margin-top: 80px;
}

.App-header h1 {
  font-size: 0.9em;
  margin: 0;
  padding: 12px;
  color: #333333; /* Dark font to contrast with light background */
  text-align: left;
}

.App-header p {
  margin: 0;
  padding: 12px;
  text-align: left;
  color: black;
  height: 4.8em; /* Enough height for 3 lines (assuming a 1.6 line-height) */
  line-height: 1.6; /* Sets the space between lines */
  overflow: hidden; /* Prevents overflow from breaking the layout */
}

.primary-btn {
background-color: #9e65e0;
color: white; /* Ensures the button text is readable */
}

.primary-btn:hover {
  background-color: #8752c3; /* Slightly darker on hover */
}

/* Container for the board images */
.board-image-container {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.board-image {
  max-width: 80%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Maintain a shadow to keep focus on the images */
}

p {
  font-size: 1.2em;
  line-height: 1.6;
  margin-bottom: 10px;
  color: #333333; /* Ensure text is readable */
}
